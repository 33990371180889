/**
 * Promisified version of window.requestAnimationFrame.
 * @returns {Promise} Promise will resolve when requestAnimationFrame callback is run.
 */
function raf() {
  return new Promise(resolve => {
    window.requestAnimationFrame(resolve);
  });
}

/**
 * Represents an HTML element with associate states
 */
export default class Animation {
  /**
   * @param {Object} options
   * @param {HTMLElement}  options.el Target element
   * @param {String} [options.state=initial] Initial state. This is also the default state.
   * @param {String} [options.stateAttribute=data-animation-state] Attribute name to update with state.
   * @param {String} [options.stateChangeAttribute=data-animation] Attribute name to
   * update with change of state.
   * @param {String} [options.endEvent=transitionend] Event to listen for at end of state change.
   * @param {Boolean} [options.hold=false] If true, changeAttribute will not be removed until the
   * next state change.
   * @param {Function} [options.onStart] Callback to execute immediate after
   * applying stateChangeAttribute.
   */
  constructor(options) {
    this._el = options.el;
    this.cancelRunning = null;

    this._state = options.state || 'initial';
    this.initialState = this._state;
    this.stateAttribute = options.stateAttribute || 'data-animation-state';
    this.stateChangeAttribute = options.stateChangeAttribute || 'data-animation';
    this.endEvent = options.endEvent || 'transitionend';
    this.hold = !!options.hold;
    this.onStart = options.onStart || (() => { /* do nothing */ });

    this.activeEventHandler = null;
  }

  /**
   * Returns target element
   *
   * @return {HTMLElement} Target element
   */
  get el() {
    return this._el;
  }

  /**
   * Returns current state
   *
   * @return {String} Current state
   */
  get state() {
    return this._state;
  }

  /**
   * Check if a state is active
   * @param {String} state State to compare
   *
   * @return {Boolean}
   */
  isState(state) {
    return state === this._state;
  }

  /**
   * Sequences a change to a new state.
   * @param {String} state Target state
   *
   * @param {Boolean} options.force Switch to final state immediately
   *
   * @param {Function} options.onStart Callback to execute immediately after
   * applying stateChangeAttribute for this state change only.
   *
   * @param {Boolean} [options.hold=false] If true, changeAttribute will not be removed until the
   * next state change.
   *
   * @return {Promise} Resolves when endEvent triggered
   */
  animateTo(state, options = {}) {
    const from = this._el.dataset[this.stateAttribute] || this._state;
    const to = state || this.initialState;
    const { force } = options;
    const hold = 'hold' in options ? options.hold : this.hold;

    return new Promise(resolve => {
      if (this.cancelRunning) {
        this.cancelRunning();
      }

      if (from === to) {
        // Removing this here fixes some lingering attributes. But why?
        this._el.removeAttribute(this.stateChangeAttribute);
        resolve(from, null);
        return;
      }

      let running = true;

      this.cancelRunning = () => {
        running = false;
        resolve(null, null);
      };

      this._el.removeEventListener(this.endEvent, this.activeEventHandler);
      this.activeEventHandler = null;

      if (force) {
        this._el.setAttribute(this.stateChangeAttribute, `${from}=>${to}`);

        this.onStart({ el: this._el, from, to });
        if (typeof options.onStart === 'function') {
          options.onStart({ el: this._el, from, to });
        }

        this._el.setAttribute(this.stateAttribute, to);
        this._state = to;

        if (!hold) {
          this._el.removeAttribute(this.stateChangeAttribute);
        }

        resolve(to, null);
        return;
      }

      raf()
        .then(() => {
          if (!running) throw new Error('cancelled');
          this._el.setAttribute(this.stateChangeAttribute, `${from}=>${to}`);

          this.onStart({ el: this._el, from, to });
          if (typeof options.onStart === 'function') {
            options.onStart({ el: this._el, from, to });
          }
          return raf();
        })
        .then(() => {
          if (!running) throw new Error('cancelled');
          this._el.removeEventListener(this.endEvent, this.activeEventHandler);

          this.activeEventHandler = e => {
            // Ignore any events bubbling up
            if (e.target !== this._el || !running) return;
            this._el.removeEventListener(this.endEvent, this.activeEventHandler);
            if (!hold) {
              this._el.removeAttribute(this.stateChangeAttribute);
            }
            resolve(to, e);
          };

          this._el.addEventListener(this.endEvent, this.activeEventHandler);

          this._el.setAttribute(this.stateAttribute, to);
          this._state = to;
        })
        .catch(error => {
          // Only catch 'cancelled' errors.
          if (error.message !== 'cancelled') throw error;
        });
    });
  }

  /**
   * Remove any event listeners
   */
  unload() {
    this._el.removeEventListener(this.endEvent, this.activeEventHandler);
    this.activeEventHandler = null;
  }
}
