import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import ProjectPreview from './project-preview';

import styles from './project-preview-grid.module.css';

function ProjectPreviewGrid(props) {
  const [active, setActive] = useState(props.nodes.map(node => false));

  function toggleProjectState(index, isActive) {
    // close the active project
    const activeProjectIndex = active.indexOf(true);
    if (activeProjectIndex != -1) {
      active[activeProjectIndex] = false;
    }

    // set the new active project
    active[index] = isActive;
    setActive([...active]);
  }

  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <div className={styles.grid}>
        {props.nodes &&
          props.nodes.map((node, index) => (
            <ProjectPreview
              {...node}
              key={node.id}
              projectIndex={index}
              active={active[index]}
              nextProject={
                props.nodes[index + 1] && props.nodes[index + 1].title
              }
              toggleProjectState={toggleProjectState}
            />
          ))}
      </div>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
    </div>
  );
}

ProjectPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: '',
};

export default ProjectPreviewGrid;
