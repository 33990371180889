import BaseBlockContent from '@sanity/block-content-to-react';
import React from 'react';
import serializers from './serializers';

const BlockText = ({ blocks }) => {
  return (
    <BaseBlockContent
      blocks={blocks}
      serializers={serializers}
      className="rte"
    />
  );
};

export default BlockText;
