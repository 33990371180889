import React from 'react';
import Img from 'gatsby-image';

import BlockContent from './block-content';
import styles from './about.module.css';

const About = ({ about, contact, background }) => {
  return (
    <div className={styles.root}>
      {background && (
        <div className={styles.backgroundWrapper}>
          <Img
            key={background.asset._id}
            fluid={background.asset.fluid}
            alt={background.alt}
            className={styles.background}
          />
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.about}>
          {about && (
            <div>
              <h1 className={styles.title}>Our History</h1>
              <BlockContent blocks={about || []} />
            </div>
          )}
        </div>
        <div className={styles.contact}>
          {contact && (
            <div>
              <h1 className={styles.title}>Contact</h1>
              <BlockContent blocks={contact || []} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
