import Animation from './Animation';
import AnimationsManager from './AnimationsManager';

function animation(options) {
  const setOptions = {
    endEvent: 'animationend',
    hold: true,
  };

  return new Animation({ options, ...setOptions });
}

function transition(options) {
  return new Animation(options);
}

export {
  Animation,
  AnimationsManager,
  animation,
  transition,
};
