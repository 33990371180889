import React from 'react';
import Img from 'gatsby-image';

import { cn } from '../lib/helpers';
import styles from './imageWithText.module.css';

const ImageWithText = ({ data }) => {
  const { heading, description, image, imageLayout } = data;
  const width = 1200;
  const layout = imageLayout === 'right' ? styles.right : styles.left;

  return (
    <div className={`${styles.root} ${layout}`}>
      <div className={styles.wrap}>
        <div className={styles.content}>
          <h3 className={styles.title}>{heading}</h3>
          {description && (
            <div className={cn(styles.text, 'rte')}>
              <p>{description}</p>
            </div>
          )}
        </div>
        <div className={styles.image}>
          <Img fluid={image.asset.fluid} width={width} alt={image.alt} />
        </div>
      </div>
    </div>
  );
};

export default ImageWithText;
