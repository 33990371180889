import React from 'react';
import Img from 'gatsby-image';
import Flickity from 'react-flickity-component';

import styles from './hero.module.css';
import Logo from '../assets/logo-resize.svg';

const fade =
  typeof window !== 'undefined' ? require('flickity-fade') : () => null;

const flickityOptions = {
  fade: true,
  wrapAround: true,
  pageDots: false,
  prevNextButtons: false,
  autoPlay: 5000,
  pauseAutoPlayOnHover: false,
};

const Hero = ({ tagline, images }) => (
  <>
    <section className={styles.root}>
      {fade && (
        <Flickity
          className={styles.carousel}
          options={flickityOptions}
          reloadOnUpdate
          static
        >
          {images.map(image => (
            <Img
              key={image.asset._id}
              fluid={image.asset.fluid}
              alt={image.alt}
              className={styles.carouselImage}
            />
          ))}
        </Flickity>
      )}

      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <Logo></Logo>
          <h1 className={styles.tagline}>{tagline}</h1>
        </div>
      </div>
    </section>
  </>
);

export default Hero;
