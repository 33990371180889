import React from 'react';
import Container from '../components/container';
import ImageWithText from '../components/imageWithText';

import styles from './alternatingContent.module.css';

const AlternatingContent = ({ content, style }) => {
  return (
    <div className={`${styles.root} ${styles[style]}`}>
      <Container>
        {content.map(block => (
          <ImageWithText key={block._key} data={block} />
        ))}
      </Container>
    </div>
  );
};

export default AlternatingContent;
