import React from 'react';

import styles from './catalogues.module.css';

const Catalogues = ({ catalogues }) => (
  <section className={styles.root}>
    <h2 className={styles.title}>Download our catalogues</h2>
    <div>
      {catalogues.map((catalogue, index) => (
        <a
          key={index}
          className={styles.button}
          href={`${catalogue.asset.url}?dl=`}
        >
          {catalogue.description}
          <svg
            className={styles.icon}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M16 11h5l-9 10-9-10h5v-11h8v11zm3 8v3h-14v-3h-2v5h18v-5h-2z"
            />
          </svg>
        </a>
      ))}
    </div>
  </section>
);

export default Catalogues;
