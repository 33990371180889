import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Flickity from 'react-flickity-component';

import { cn, buildImageObj } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';
import { transition } from '../lib/animations';
import BlockText from './block-text';

import styles from './project-preview.module.css';
import { responsiveTitle3 } from './typography.module.css';

const flickityOptions = {
  imagesLoaded: true,
  cellAlign: 'left',
  pageDots: false,
  prevNextButtons: false,
  lazyLoad: 2,
};

function ProjectPreview(props) {
  const { projectIndex, active, nextProject, toggleProjectState } = props;
  const project = useRef(null);
  const projectInner = useRef(null);
  const firstRender = useRef(true);

  const [flkty, setFlkty] = useState(null); // TODO change this to useRef?
  // Set animation when projectInner ref is set
  const animation = useMemo(
    () =>
      transition({
        el: projectInner.current,
        state: active ? 'open' : 'closed',
      }),
    [projectInner.current],
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (active) {
      openProject().then(() => scrollToProject());
    } else {
      closeProject();
    }
  }, [active]);

  useEffect(() => {
    const prev = project.current.querySelector('.button--prev');
    const next = project.current.querySelector('.button--next');
    prev.disabled = true;

    if (!flkty) {
      return;
    }

    flkty.on('select', () => {
      prev.disabled = flkty.selectedIndex === 0;
      next.disabled = flkty.selectedIndex === flkty.cells.length - 1;
    });

    flkty.on(
      'dragStart',
      () => (document.ontouchmove = e => e.preventDefault()),
    );
    flkty.on('dragEnd', () => (document.ontouchmove = () => true));
  }, [flkty]);

  /**
   *
   *
   * @param {*} event
   */
  function handleTitleClick(event) {
    event.preventDefault();

    toggleProjectState(projectIndex, !active);
  }

  function handleNextProjectClick() {
    toggleProjectState(projectIndex, false);
    toggleProjectState(projectIndex + 1, true);
  }

  function handlePrevProjectClick() {
    toggleProjectState(projectIndex, false);
    toggleProjectState(projectIndex - 1, true);
  }

  function handleCloseClick(event) {
    toggleProjectState(projectIndex, false);
  }

  /**
   *
   *
   * @param {*} el
   */
  function scrollToProject(el) {
    const { offsetTop } = project.current;

    scroll({
      top: offsetTop,
      behavior: 'smooth',
    });
  }

  function openProject() {
    return animation.animateTo('open').then(() => {
      flkty.resize();
    });
  }

  function closeProject() {
    return animation.animateTo('closed');
  }

  function goToNextImage() {
    flkty.next();
  }

  function goToPrevImage() {
    flkty.previous();
  }

  return (
    <article ref={project} className={cn(styles.root, active && styles.active)}>
      <header className={styles.header}>
        <a
          className={styles.title}
          href={`#${props.slug.current}`}
          onClick={handleTitleClick}
        >
          {props.title}
          {active && <span className={styles.location}>{props.location}</span>}
        </a>
        <nav className={styles.galleryNav}>
          <button className="button--prev" onClick={goToPrevImage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              transform="rotate(180)"
            >
              <path d="M24 12l-12-8v5h-12v6h12v5z" />
            </svg>
          </button>

          <button className="button--next" onClick={goToNextImage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 12l-12-8v5h-12v6h12v5z" />
            </svg>
          </button>

          {projectIndex > 0 && (
            <button onClick={handlePrevProjectClick}>
              <svg
                width="16"
                height="24"
                viewBox="0 0 16 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 0L0 12h5v12h6V12h5L8 0z"
                  fill="#000"
                  fillRule="evenodd"
                />
              </svg>
            </button>
          )}

          {nextProject && (
            <button onClick={handleNextProjectClick}>
              <svg
                width="16"
                height="24"
                viewBox="0 0 16 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 24l8-12h-5V0H5v12H0l8 12z"
                  fill="#000"
                  fillRule="evenodd"
                />
              </svg>
            </button>
          )}

          <button className={styles.close} onClick={handleCloseClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
            </svg>
          </button>
        </nav>
      </header>

      <div ref={projectInner} className={styles.inner}>
        <Flickity
          flickityRef={flickityInstance => setFlkty(flickityInstance)}
          className={styles.gallery}
          options={flickityOptions}
          reloadOnUpdate={false}
          static={true}
        >
          <div className={cn(styles.projectDescription, styles.galleryItem)}>
            <BlockText blocks={props._rawExcerpt} />
          </div>

          {props.images.map(image => (
            <div
              key={image.asset._id}
              className={cn(styles.galleryItemImage, styles.galleryItem)}
              style={{ width: `${image.asset.fluid.aspectRatio}em` }}
            >
              <Img
                fluid={image.asset.fluid}
                alt={image.alt}
                style={{ height: '100%' }}
              />
            </div>
          ))}

          {nextProject && (
            <div className={cn(styles.nextProject, styles.galleryItem)}>
              <button
                className={styles.nextButton}
                onClick={handleNextProjectClick}
              >
                <span>
                  <span>Next: {nextProject}</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  >
                    <path d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z" />
                  </svg>
                </span>
              </button>
            </div>
          )}
        </Flickity>
      </div>
    </article>
  );
}

export default ProjectPreview;
