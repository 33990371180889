import React from 'react';
import { graphql } from 'gatsby';
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../lib/helpers';
import About from '../components/about';
import AlternatingContent from '../components/alternatingContent';
import Catalogues from '../components/catalogues';
import FeaturedText from '../components/featuredText';
import GraphQLErrorList from '../components/graphql-error-list';
import Hero from '../components/hero';
import ProjectPreviewGrid from '../components/project-preview-grid';
import SEO from '../components/seo';
import Layout from '../containers/layout';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    home: sanityHomePage {
      tagline
      heroImages {
        alt
        asset {
          _id
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      intro
      content {
        _key
        heading
        description
        image {
          caption
          alt
          asset {
            _id
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        imageLayout
      }
      catalogues {
        asset {
          url
        }
        description
      }
      aboutImage {
        asset {
          _id
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawAbout
      _rawContact
    }
    projects: allSanitySampleProject(
      limit: 12
      sort: { fields: priority, order: ASC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          images {
            alt
            caption
            asset {
              _id
              fluid(maxWidth: 2000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          location
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const home = (data || {}).home;
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.',
    );
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <h1 hidden>Welcome to {site.title}</h1>
      <Hero tagline={home.tagline} images={home.heroImages}></Hero>
      {home.intro && <FeaturedText text={home.intro} />}
      {home.content && (
        <AlternatingContent content={home.content} style="tint" />
      )}
      {home.catalogues && <Catalogues catalogues={home.catalogues} />}
      {projectNodes && (
        <ProjectPreviewGrid title="Featured Projects" nodes={projectNodes} />
      )}
      <About
        about={home._rawAbout}
        contact={home._rawContact}
        background={home.aboutImage}
      ></About>
    </Layout>
  );
};

export default IndexPage;
